import React from "react";
import {createMemoryHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product
import Components from "./Components/Components.jsx";
import LandingPage from "./LandingPage/LandingPage.jsx";
import ContactPage from "./ContactPage/ContactPage.jsx";
import GalleryPage from "./GalleryPage/GalleryPage.jsx";
import AboutPage from "./AboutPage/AboutPage.jsx"
import WWDPage from "./WWDPage/WWDPage.jsx"
import ESGPage from "./ESGPage/ESGPage.jsx"
import TeamPage from "./TeamPage/TeamPage.jsx"
import PartnersPage from "./PartnersPage/PartnersPage.jsx"
import NewsPage from "./NewsPage/NewsPage.jsx"
import CsPage from "./CsPage/CsPage.jsx"
import DevelopmentsPage from "./DevelopmentsPage/DevelopmentsPage.jsx"


let hist = createMemoryHistory();

export default () => (
  <Router history={hist}>
    <Switch>
      <Route path="/about" component={AboutPage} />
      <Route path="/team" component={TeamPage} />
      <Route path="/esg" component={ESGPage} />
      <Route path="/partners" component={PartnersPage} />
      <Route path="/what-we-do" component={WWDPage} />
      <Route path="/location" component={LandingPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/gallery-page" component={GalleryPage}/>
      <Route path="/news-research" component={NewsPage}/>
      <Route path="/case-studies" component={CsPage}/>
      <Route path="/developments" component={DevelopmentsPage}/>
      <Route path="/" component={Components} />
    </Switch>
  </Router>
);
