import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Business from "@material-ui/icons/Business";
import EventSeat from "@material-ui/icons/EventSeat";
import Favorite from "@material-ui/icons/Favorite";
// React icons
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import profile from "assets/img/faces/christian.jpg";

import render1 from "assets/img/examples/render1.png";
import render2 from "assets/img/examples/render2.png";
import render3 from "assets/img/examples/render3.jpg";
import portrait from "assets/img/examples/portrait.png";
import two from "assets/img/2.jpg";
import three from "assets/img/3.jpg";
import four from "assets/img/4.jpg";
import five from "assets/img/5.jpg";
import six from "assets/img/6.jpg";
import seven from "assets/img/7.jpg";
import eight from "assets/img/8.jpg";


import galleryPageStyle from "assets/jss/material-kit-react/views/galleryPage.jsx";

class GalleryPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <div>
        <Header
          color="transparent"
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/2.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <div className={classes.description}>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eget diam augue. Sed eget fringilla ipsum, eu consectetur dolor. In nec vulputate lorem, nec vulputate metus. Curabitur suscipit ut mi vel imperdiet. Duis et finibus velit. Maecenas feugiat eros in metus bibendum iaculis. Aliquam erat volutpat. Donec feugiat dapibus ex, vel dapibus odio fermentum sed. Etiam nunc arcu, pharetra at efficitur nec, posuere quis arcu. Cras vel mi mattis, blandit orci non, mollis eros. Phasellus lacinia elit at diam euismod, posuere posuere eros egestas. Maecenas et consequat diam.
                </p> */}
              </div>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                  <NavPills
                    alignCenter
                    color="info"
                    tabs={[
                      {
                        tabButton: "Renders",
                        // tabIcon: Business,
                        tabContent: (
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                              <img
                                alt="..."
                                src={render3}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={render1}
                                className={navImageClasses}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <img
                                alt="..."
                                src={render2}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={portrait}
                                className={navImageClasses}
                              />
                            </GridItem>
                          </GridContainer>
                        )
                      },
                      {
                        tabButton: "Interior Examples",
                        // tabIcon: EventSeat,
                        tabContent: (
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                              <img
                                alt="..."
                                src={two}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={three}
                                className={navImageClasses}
                              />
                                                            <img
                                alt="..."
                                src={four}
                                className={navImageClasses}
                              />
                                                            <img
                                alt="..."
                                src={five}
                                className={navImageClasses}
                              />
                                                            <img
                                alt="..."
                                src={six}
                                className={navImageClasses}
                              />
                                                            <img
                                alt="..."
                                src={seven}
                                className={navImageClasses}
                              />
                                                            <img
                                alt="..."
                                src={eight}
                                className={navImageClasses}
                              />
                            </GridItem>
                          </GridContainer>
                        )
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(galleryPageStyle)(GalleryPage);